import { FC, useEffect, useRef, useState } from "react";
import MarqueeBase from "react-fast-marquee";

import { Box, chakra } from "@chakra-ui/react";

const Marquee: FC<{ duration?: number }> = ({ children, duration = 15 }) => {
  const [speed, setSpeed] = useState<number>(66);
  const textElRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const width = textElRef.current?.offsetWidth;
    if (!width) {
      return;
    }
    setSpeed(width / duration);
  }, [duration]);

  return (
    <Box height="1.5625rem">
      <MarqueeBase gradient={false} speed={speed}>
        <chakra.span ref={textElRef} pr="2rem">
          {children}
        </chakra.span>
      </MarqueeBase>
    </Box>
  );
};

export default Marquee;
